<template>
  <transition>
    <InfiniteCharacters
      limited
      :pagination="false"
      :loading="featuredCharLoading"
      :characters="featuredCharacters"
      :title="get(featuredCharacters, 'length') ? 'Check out some character profiles!' : 'Trending Characters'"
      title-class="text-center"
    />
  </transition>
</template>

<script lang="ts" setup>
import InfiniteCharacters from '@/shared/components/InfiniteCharacters.vue';
import { getHomeFeaturedCharacters } from '@/shared/actions/characters';

const featuredCharacters = ref<any[]>([]);
const featuredCharLoading = ref(true);

const fetchFeaturedCharacters = async () => {
  featuredCharLoading.value = true;

  const response = await getHomeFeaturedCharacters();

  featuredCharacters.value = response.value.results
    .map((i: any) => i.character)
    .filter((i: any) => !i.is_nsfw)
    .slice(0, 6);

  featuredCharLoading.value = false;
};

onMounted(() => {
  fetchFeaturedCharacters();
});
</script>

<style lang="sass" scoped></style>
